import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./ApplicationStore.js";
import './localization/i18n.js';

// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-quill/dist/quill.snow.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import 'bootstrap-social/bootstrap-social.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-datetime/css/react-datetime.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dual-listbox/lib/react-dual-listbox.css';

//js
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

// ========================================

import { REGION } from '../src/util/Constant';

const SWCTheme = React.lazy(() => import('../src/components/theme/SWC'));
const RejangTheme = React.lazy(() => import('../src/components/theme/Rejang'));

/// <summary>
/// Author: A
/// </summary>
const Theme = ({ children }) => {
    const CHOSEN_THEME = process.env.REACT_APP_NAME;
    return (
        <>
            <React.Suspense fallback={<></>}>
                {(CHOSEN_THEME === REGION._SWC ) && <SWCTheme />}
                {(CHOSEN_THEME === REGION._REJANG) && <RejangTheme />}
            </React.Suspense>
            {children}
        </>
    )
}

ReactDOM.render(
    <Theme>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </Theme>,
    document.getElementById('root')
);